import React, { useState } from 'react';
import Rating from 'react-rating';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faEarthAmericas } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import styles from './ExpandableTable.module.css';

const ExpandableTable = () => {
  const [expandedRow, setExpandedRow] = useState(null);

  const data = [
    { id: 1, col1: '1. Malta 🇲🇹', col2: '45%', col3: '40%', col4: '9', col5: '8' },
    { id: 2, col1: '2. Switzerland 🇨🇭', col2: '33%', col3: '30%', col4: '8', col5: '9' },
    { id: 3, col1: '3. Cayman Islands 🇰🇾', col2: '29%', col3: '22%', col4: '7', col5: '9' },
    { id: 4, col1: '4. Luxembourg 🇱🇺', col2: '29%', col3: '17%', col4: '8', col5: '8' },
    { id: 5, col1: '5. United States 🇺🇸', col2: '15%', col3: '22%', col4: '7', col5: '7' },
    { id: 6, col1: '6. Isle of Man 🇮🇲', col2: '10%', col3: '10%', col4: '7', col5: '8' },
    { id: 7, col1: '7. United Kingdom 🇬🇧', col2: '12%', col3: '7%', col4: '8', col5: '7' },
    { id: 8, col1: '8. Hong Kong 🇭🇰', col2: '5%', col3: '8%', col4: '7', col5: '8' },
    { id: 9, col1: '9. Ireland 🇮🇪', col2: '3%', col3: '7%', col4: '6', col5: '8' },
    { id: 10, col1: '10. Panama 🇵🇦', col2: '0%', col3: '7%', col4: '6', col5: '7' },
  ];

  const handleExpandClick = (id) => {
    setExpandedRow(expandedRow === id ? null : id);
  };

  return (
      <table id="firstTable" className={styles.mainTable}>
        <thead>
        <tr>
          <th></th>
          <th className={styles.countryWidth}>Tax Savings</th>
          <th>Operational Savings</th>
          <th>Legal Protection</th>
          <th>Privacy</th>
          <th>Local Experts</th>
        </tr>
        </thead>
        <tbody>
        {data.map((row) => (
            <React.Fragment key={row.id}>
              <tr
                  className={`${styles.borderBlackCell} ${expandedRow !== row.id ? styles.hoverEffect : ''}`}
                  onClick={() => handleExpandClick(row.id)}
              >
                <td className={styles.countryName} style={{ paddingTop: '13px' }}>{row.col1}</td>
                <td style={{ paddingTop: '13px' }}>{row.col2}</td>
                <td style={{ paddingTop: '13px' }}>{row.col3}</td>
                <td style={{ paddingTop: '13px' }}>{row.col4}</td>
                <td style={{ paddingTop: '13px' }}>{row.col5}</td>
                <td style={expandedRow === row.id ? { backgroundColor: '#F2F2F2', paddingTop: '13px' } : { paddingTop: '13px' }}>
                  <button className={styles.buttonIcon}>
                    {expandedRow === row.id ? (
                        <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" className={styles.icon}>
                          <path d="M12 7.59l-5.29 5.3-1.42-1.42 6.71-6.7 6.71 6.7-1.42 1.42z"></path>
                        </svg>
                    ) : (
                        <svg width="24" height="24" viewBox="0 0 24 24" focusable="false" className={styles.icon}>
                          <path d="M12 16.41l-6.71-6.7 1.42-1.42 5.29 5.3 5.29-5.3 1.42 1.42z"></path>
                        </svg>
                    )}
                  </button>
                </td>
              </tr>
              {expandedRow === row.id && (
                  <tr className={styles.expansionRow}>
                    <td colSpan="6" className={styles.expansionCell}>
                      <table className={styles.nestedTable}>
                        <tbody>
                        <tr style={{backgroundColor: '#F2F2F2'}}>
                          <td className={styles.partnerName}>Griffiths + Associates</td>
                          <td colSpan="2" className={styles.partnerDescription}>Specializes in company formation, tax advisory, accounting, and compliance services.</td>
                          <td className={styles.partnerRanking}>
                              <Rating
                                  initialRating={5}
                                  readonly
                                  emptySymbol={<span className={styles.emptyStar}>&#9733;</span>}
                                  fullSymbol={<span className={styles.fullStar}>&#9733;</span>}
                                  fractions={2}
                              />

                              <span style={{ fontSize: '18px', fontWeight: 'bold', paddingLeft: '5px' }}>5.00</span>
                          </td>
                          <td className={styles.partnerContactLinks}>
                            Send me a quote<br />Book a call
                          </td>
                            <td className={styles.partnerContacts}>
                                info@griffithsassoc.com<br/> +356 2738 3631<br/>
                                <div className={styles.logosContainer}>
                                    <FontAwesomeIcon icon={faEarthAmericas} width="18" height="18"
                                                     style={{fontSize: '25px', color: '#9a9c99'}}/>
                                    <FontAwesomeIcon icon={faLinkedinIn} width="18" height="18" style={{fontSize: '24px', color: '#9a9c99'}}/>
                                </div>
                            </td>
                        </tr>
                        <tr style={{backgroundColor: '#F2F2F2'}}>
                            <td className={styles.partnerName}>ACT Advisory Services Limited</td>
                            <td colSpan="2" className={styles.partnerDescription}>Specializes in corporate services, tax
                                planning, financial advisory, and regulatory compliance.</td>
                          <td className={styles.partnerRanking}>
                              <Rating
                                  initialRating={4.70}
                                  readonly
                                  emptySymbol={<span className={styles.emptyStar}>&#9733;</span>}
                                  fullSymbol={<span className={styles.fullStar}>&#9733;</span>}
                                  fractions={2}
                              />
                            <span style={{ fontSize: '18px', fontWeight: 'bold', paddingLeft: '5px' }}>4.80</span>
                          </td>
                          <td className={styles.partnerContactLinks}>
                            Send me a quote<br />Book a call
                          </td>
                            <td className={styles.partnerContacts}>
                                info@act.com.mt<br/> +356 2137 8672<br/>
                                <div className={styles.logosContainer}>
                                    <FontAwesomeIcon icon={faEarthAmericas} width="18" height="18"
                                                     style={{fontSize: '25px', color: '#9a9c99'}}/>
                                    <FontAwesomeIcon icon={faLinkedinIn} width="18" height="18"
                                                     style={{fontSize: '24px', color: '#9a9c99'}}/>
                                </div>
                            </td>
                        </tr>
                        <tr style={{backgroundColor: '#F2F2F2'}}>
                            <td className={styles.partnerName}>CSB Group</td>
                            <td colSpan="2" className={styles.partnerDescription}>Provides company incorporation
                                back-office services, tax advisory, and regulatory compliance.
                            </td>
                            <td className={styles.partnerRanking}>
                              <Rating
                                  initialRating={4.20}
                                  readonly
                                  emptySymbol={<span className={styles.emptyStar}>&#9733;</span>}
                                  fullSymbol={<span className={styles.fullStar}>&#9733;</span>}
                                  fractions={2}
                              />
                            <span style={{ fontSize: '18px', fontWeight: 'bold', paddingLeft: '5px' }}>4.15</span>
                          </td>
                          <td className={styles.partnerContactLinks}>
                            Send me a quote<br />Book a call
                          </td>
                            <td className={styles.partnerContacts}>
                                info@csbgroup.com<br/> +356 2557 2557<br/>
                                <div className={styles.logosContainer}>
                                    <FontAwesomeIcon icon={faEarthAmericas} width="18" height="18"
                                                     style={{fontSize: '25px', color: '#9a9c99'}}/>
                                    <FontAwesomeIcon icon={faLinkedinIn} width="18" height="18"
                                                     style={{fontSize: '24px', color: '#9a9c99'}}/>
                                </div>
                            </td>
                        </tr>
                        <tr style={{backgroundColor: '#F2F2F2'}}>
                            <td colSpan="6" className={styles.loadCell}>
                                <button className={styles.buttonLoadMore}>Load More</button>
                            </td>
                        </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
              )}
            </React.Fragment>
        ))}
        </tbody>
      </table>
  );
};

export default ExpandableTable;