import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import config from '../config';
import styles from './Login.module.css';

function Login({ isOpen, formType, onLogin, onRequestClose, onInputFocus }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [isLogin, setIsLogin] = useState(true);  

  useEffect(() => {
    setIsLogin(formType === 'LOGIN');  
  }, [formType]);


  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(config.API_BASE_URL + '/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
        credentials: 'include' 
      });

      const data = await response.json();
      if (data.success) {
        console.log("Login successful");
        onLogin(true, data.user_id);
      } else {
        console.log("Login failed");
        alert(data.message);
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  const handleRegisterSubmit = async (event) => {
    event.preventDefault();
    
    console.log("Register attempt for:", email);
    
    alert("Check your email to verify account");
    
    onLogin(true);
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  const customStyles = {
    content: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      transform: 'translate(-50%, -50%)',
      margin: '0',
      padding: '0',
      border: 'none',
      background: '#fff',
      overflow: 'visible',
      width: 'auto',
      height: 'auto'
    },
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1000
    }
  };

  

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={isLogin ? "Login Modal" : "Register Modal"}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={true}
    >
      <div className="login-container" style={{ padding: '20px' }}>
        <img src="images/logo.png" alt="Logo" className="login-logo" />
        {isLogin ? (
          <form onSubmit={handleLoginSubmit} className="login-form">
            <div className="fields-container">
              <label>Username:</label>
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                onFocus={onInputFocus}
                required
              />
            </div>
            <div className="fields-container">
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onFocus={onInputFocus}
                required
              />
            </div>
            <div className="buttonLoginContainer">
              <button type="submit">Login</button>
            </div>
          </form>
        ) : (
          <form className="login-form">
            <div className="fields-container">
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                onFocus={onInputFocus}
                required
              />
            </div>
            <div className="buttonLoginContainer">
              <button type="submit">Register</button>
            </div>
          </form>
        )}
      </div>
    </Modal>
  );
}

export default Login;

