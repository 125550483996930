// src/config.js
const config = {
    

    API_BASE_URL: 'https://david.tax.network',
    //API_BASE_URL: 'http://localhost:8000',
    PATENT_TEXT: 'Patent pending #',
    PATENT_CODE: '18/637,513 (USPTO)',
    

};


    
export default config;
