import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './TopBar.module.css';


const TopBar = ({ loggedIn, openLoginModal, openAbout, closePages, showCountriesTable, setShowCountriesTable, openRegisterModal, closeChat, openLocalPartners, openMaxInvestments, openPricing }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);

    const handleLogoClick = () => {
        window.location.href = 'https://tax.network'; 
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 768);
            if (window.innerWidth > 768) {
                setIsMenuOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className={`navbar navbar-expand-lg ${styles.leftPanel2}`}>
            <div className={styles.containerNav}>
                <div className={showCountriesTable ? styles.logoContainer : styles.logoContainerPointer}>

                    <img src="/images/logo-tax-network.svg" alt="Logo" className={styles.logo2} onClick={handleLogoClick} />
                    
                </div>

                <div id="mainMenu" className={`row align-items-center ${isSmallScreen ? styles.hide : ''}`}>
                    <div className="d-sm-flex col-sm-auto justify-content-start" style={{ paddingLeft: '40px' }}>
   
                    <a className={styles.headerNavLink} aria-current="page" href="https://tax.network/how-it-works.php">Maximize investments</a>
                    </div>
                    <div className="d-sm-flex col-sm-auto justify-content-start" style={{ paddingLeft: '15px' }}>
   
                    <a className={styles.headerNavLink} aria-current="page" href="https://tax.network/global-ranking.php">Global Ranking</a>
                    </div>
                    <div className="d-sm-flex col-sm-auto justify-content-start" style={{ paddingLeft: '15px' }}>
   
                    <a className={styles.headerNavLink} aria-current="page" href="https://tax.network/local-experts.php">Local experts</a>
                    </div>
                    
          
                </div>


                {/*
                <div id="mainMenu" className={`row align-items-center ${isSmallScreen ? styles.hide : ''}`}>
                    <div className="d-sm-flex col-sm-auto justify-content-end">
                        <a className="btn btn-primary" href="https://cal.com/baccaro" target="_blank" rel="noopener noreferrer" style={{ backgroundColor: '#4145CF', border: 'none', verticalAlign: "text-top"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone" viewBox="0 0 16 16">
                                <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                            </svg>
                            &nbsp;&nbsp;Book a call
                        </a>
                    </div>
                    <div className="d-sm-flex col-sm-auto justify-content-end">
                        <a className="btn btn-primary btn-chat" href="#" style={{ backgroundColor: '#4145CF', border: 'none', verticalAlign: "text-top"}} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-floppy" viewBox="0 0 16 16" >
                                <path d="M11 2H9v3h2z" />
                                <path d="M1.5 0h11.586a1.5 1.5 0 0 1 1.06.44l1.415 1.414A1.5 1.5 0 0 1 16 2.914V14.5a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 14.5v-13A1.5 1.5 0 0 1 1.5 0M1 1.5v13a.5.5 0 0 0 .5.5H2v-4.5A1.5 1.5 0 0 1 3.5 9h9a1.5 1.5 0 0 1 1.5 1.5V15h.5a.5.5 0 0 0 .5-.5V2.914a.5.5 0 0 0-.146-.353l-1.415-1.415A.5.5 0 0 0 13.086 1H13v4.5A1.5 1.5 0 0 1 11.5 7h-7A1.5 1.5 0 0 1 3 5.5V1H1.5a.5.5 0 0 0-.5.5m3 4a.5.5 0 0 0 .5.5h7a.5.5 0 0 0 .5-.5V1H4zM3 15h10v-4.5a.5.5 0 0 0-.5-.5h-9a.5.5 0 0 0-.5.5z" />
                            </svg>
                            &nbsp;&nbsp;Save for later
                        </a>
                    </div>
                    <div className="d-sm-flex col-sm-auto justify-content-end">
                        <a className="btn btn-primary btn-chat" aria-current="page" href="https://juris.tax/dashboard/dashboard.php" style={{ backgroundColor: '#4145CF', border: 'none', verticalAlign: "text-top"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-percent" viewBox="0 0 16 16">
                                <path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0M4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3m0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
                            </svg>
                            &nbsp;&nbsp;My strategies
                        </a>
                    </div>
                    <div className="d-sm-flex col-sm-auto justify-content-end">
                        <a aria-current="page" href="../pane/account.php">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-person-circle" viewBox="0 0 16 16">
                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
                            </svg>
                        </a>
                    </div>
                </div> */}

                <button className={styles.hamburger} onClick={toggleMenu}>
                    ☰
                </button>

                <div className={`${styles.hamburgerMenu} ${isMenuOpen ? styles.open : ''}`}>
                    <div className={styles.closeMenu} onClick={closeMenu}>X</div>
                    <button className={styles.menuLink_hamburguer} onClick={openMaxInvestments}>Maximize Investments</button>
                    <button className={styles.menuLink_hamburguer} onClick={openLocalPartners}>Local Experts</button>
         
                    {/*
                    {!loggedIn ? (
                        <>
                            <button onClick={openLoginModal} className={styles.menuLink_hamburguer}>Login</button>
                            <button onClick={openRegisterModal} className={styles.menuLink_hamburguer}>Register</button>
                        </>
                    ) : (
                        <>
                            <button className={styles.menuLink_hamburguer}>My Account</button>
                            <button className={styles.menuLink_hamburguer}>Logout</button>
                        </>
                    )} */}
                </div>
            </div>
        </div>
    );
};

export default TopBar;
