import React, { useState, useEffect } from 'react';
import '@fortawesome/fontawesome-svg-core/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import TopBar from './components/TopBar';
import Chat from './components/Chat';
import Login from './components/Login';
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios';

const ENABLE_SESSION_CHECK = true;

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [userID, setUserID] = useState(-1);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [chatVisible, setChatVisible] = useState(true);
  const [userUUID, setUserUUID] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  const handleLogin = (status, userID = -1) => {
    setLoggedIn(status);
    setUserID(userID);
    if (status) {
      const uuid = uuidv4();
      setUserUUID(uuid);
    }
    setModalIsOpen(!status);
  };

  const handleInputFocus = () => {
    setChatVisible(true);
  };

  useEffect(() => {
    const isSmall = window.innerWidth < 768;
    setIsSmallScreen(isSmall);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const isSmall = window.innerWidth < 768;
      setIsSmallScreen(isSmall);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const openLoginModal = () => {
    setModalIsOpen(true);
  };


  const checkAuthentication = async () => {
    try {
      /*const response = await axios.get('https://tax.network/check_session.php', {
        withCredentials: true
      });
      */
      const response = await axios.get('https://tax.network/check_session.php', {
        withCredentials: true
      });
      if (!response.data.authenticated) {
        window.location.href = 'https://tax.network/enter.php#login';
      }
    } catch (error) {
        window.location.href = 'https://tax.network/enter.php#login';
    }
  };

  
  useEffect(() => {
    if (ENABLE_SESSION_CHECK) {
      checkAuthentication();
    }
  }, []);


  return (
    <div className="App">
      <TopBar openLoginModal={openLoginModal} />
      <div className="ChatContainer">
        <Chat
          loggedIn={loggedIn}
          userID={userID}
          userUUID={userUUID}
          openLoginModal={openLoginModal}
        />
      </div>
      <Login
        isOpen={modalIsOpen}
        onLogin={handleLogin}
        onRequestClose={() => setModalIsOpen(false)}
        onInputFocus={handleInputFocus}
      />
    </div>
  );
}

export default App;
