import React, { useState, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import config from '../config';
import styles from './Chat.module.css';
import ExpandableTable from '../features/ExpandableTable.js'

function Chat({ loggedIn, userID, userUUID, openLoginModal, openRegisterModal }) {
    const [messages, setMessages] = useState([]);
    const [userInput, setUserInput] = useState('');
    const [responses, setResponses] = useState([]);
    const [responseLoaded, setResponseLoaded] = useState(new Set());
    const [currentQuestionId, setCurrentQuestionId] = useState(1);
    const [currentDisplayQuestionId, setDisplayCurrentQuestionId] = useState(1);
    const [lastQuestionId, setLastQuestionId] = useState(47);
    const [initialLoading, setInitialLoading] = useState(true);
    const [userIP, setUserIP] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const messagesContainerRef = useRef(null);
    const inputRef = useRef(null);
    const [firstQuestionLoaded, setFirstQuestionLoaded] = useState(false);
    const isMobile = window.innerWidth < 768;
    const [isFinished, setIsFinished] = useState(false);
    const [interactionHistory, setInteractionHistory] = useState([]);
    const [showLoadingAnimation, setShowLoadingAnimation] = useState(false);
    const [startRanking, setStartRanking] = useState(false);
    const [welcomeMessageShown, setWelcomeMessageShown] = useState(false);
    const initialLoadRef = useRef(false);
    const [homeAdded, setHomeAdded] = useState(false);
    const [firstChatLoad, setFirstChatLoad] = useState(false);




    useEffect(() => {
        const img = new Image();
        img.src = "images/chat-icon-robot-30-30.png";
        img.src = "images/chat-icon-client-30-30.png";
        img.src = "images/three-dots.gif";
    }, []);

    useEffect(() => {
        const fetchIP = async () => {
            const response = await axios.get('https://api.ipify.org?format=json');
            setUserIP(response.data.ip);
        };
        fetchIP();
    }, []);


    const getWelcomeMessage = useCallback(() => {

        const baseMessage = `


        <span>&#128075;</span> Welcome, I'm <strong>David</strong>, your expert bot at <strong>Tax.Network</strong>,
        <br/><br/>
        Discover <strong>how and where your business is best treated.</strong>
       
        Create <strong>efficient tax and estate planning in the most appropriate jurisdiction</strong> with our AI-based technology and proprietary dynamic data set.
        <br/><br/>
        Enjoy the <strong> discretion, confidentiality</strong>, and high standards in data security—protecting <strong>your privacy is our top priority.</strong>
        <br/><br/>
        
        
        Let’s start with your <strong>personal and succession planning</strong> <span>&#129309;</span>
        
        <br/><br/>
        
      

        `;

            /*
            old end space
             <div style="margin-top: 4em;">
             */
        const baseMessageMobile = `                                               
        
        
         <span>&#128075;</span> Welcome, I'm <strong>David</strong>, your expert bot at <strong>Tax.Network</strong>,
        <br/><br/>
        Discover <strong>how and where your business is best treated.</strong>
       
        Create <strong>efficient tax and estate planning in the most appropriate jurisdiction</strong> with our AI-based technology and proprietary dynamic data set.
        <br/><br/>
        Enjoy the <strong> discretion, confidentiality</strong>, and high standards in data security—protecting <strong>your privacy is our top priority.</strong>
        <br/><br/>
        
        
        Let’s start with your <strong>personal and succession planning</strong> <span>&#129309;</span>
        
        <br/><br/>
        <div style="margin-top: 0.5em;">
        `;



        return isMobile ? baseMessageMobile : baseMessage;
    }, [isMobile]);

    /*
        const scrollToTop = () => {
            if (messagesContainerRef.current) {
                messagesContainerRef.current.scrollTop = -2500;
            }
        };
*/
    useEffect(() => {
        if (initialLoading && !firstQuestionLoaded) {
            async function loadInitialQuestion() {
                try {
                    const welcomeMsg = getWelcomeMessage();


                    /*
                    old bot

                    const response = await axios.post(config.API_BASE_URL + '/firstQuestion.php');


                    const formattedQuestion = `
                        <div style="display: flex; align-items: center; gap: 15px; margin-bottom:1em">
                            <img src="images/chat-icon-robot-30-30.png">
                            <strong style="margin: 0;">Tax.Network:</strong>
                        </div>
                        <div style="padding-left: 43px">
                          ${response.data.question}
                        </div>
                        `;



                    simulateTypingEffect(welcomeMsg, true, true, () => {

                        simulateTypingEffect(formattedQuestion, true, true, () => {
                            setInitialLoading(false);
                            setFirstQuestionLoaded(true);
                        });
                    });*/

                    const formattedQuestion = `
                       
                        <div>
                          Can you please detail your financial, legal, and tax situation, including your current residence, tax residency status, family members (including any living in different countries), and any specific succession goals, charitable intentions, or family dynamics you want to address in your estate plan?
                        </div>
                      `;

                    simulateTypingEffect(welcomeMsg, true, true, () => {

                        simulateTypingEffect(formattedQuestion, true, true, () => {
                            setInitialLoading(false);
                            setFirstQuestionLoaded(true);
                        });
                    });


                } catch (error) {
                    console.error('Error loading the initial question:', error);
                }
            };
            loadInitialQuestion();
        }
    }, [initialLoading, currentQuestionId]);


    /*
        useEffect(() => {
            if (messagesContainerRef.current) {
                const { scrollHeight, clientHeight, scrollTop } = messagesContainerRef.current;
                const isAtBottom = scrollHeight - scrollTop === clientHeight;
                if (isAtBottom) {
                    messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
                }
            }
        }, [messages]);
    
    
    
        useEffect(() => {
            if (messagesContainerRef.current) {
                messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
            }
        }, [messages, isTyping]);
    
    
    
    */


    const simulateTypingEffect = (text, isHTML, addNewMessage = true, callback) => {
        setIsTyping(true);
        let i = 0;
        const speed = 10;
        let fullText = "";
        let buffer = "";
        let isTag = false;

        function typeWriter() {
            if (i < text.length) {
                buffer += text.charAt(i);
                const char = text.charAt(i);
                fullText += char;

                if (char === '<') {
                    isTag = true;
                } else if (char === '>') {
                    isTag = false;
                }


                if (!isTag && (buffer.length > 5 || buffer.includes('<br>') || buffer.includes('<strong>') || i === text.length - 1)) {
                    const messageToAdd = { text: fullText, sender: 'bot', isBold: false, isHTML: true };


                    if (addNewMessage) {
                        setMessages(prev => [...prev, messageToAdd]);
                        addNewMessage = false;
                    } else {
                        setMessages(prev => [...prev.slice(0, -1), messageToAdd]);
                    }

                    buffer = "";
                }

                i++;
                setTimeout(typeWriter, speed);
            } else {

                if (buffer.length > 0) {
                    const messageToAdd = { text: fullText, sender: 'bot', isBold: false, isHTML: true };
                    setMessages(prev => [...prev, messageToAdd]);
                }

                buffer = "";
                setIsTyping(false);
                if (callback) {
                    callback();
                }

                if (loggedIn && inputRef.current && !isTyping) {
                    inputRef.current.focus();
                }
            }
        }
        typeWriter();
    };


    useEffect(() => {

        if (!isTyping && inputRef.current && firstQuestionLoaded && currentQuestionId > 1) {
            inputRef.current.focus();
        }


    }, [isTyping, firstQuestionLoaded]);



    useEffect(() => {
        if (messagesContainerRef.current && messagesContainerRef.current.scrollHeight > 1500) {

            const scroll = () => {
                messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
            };

            scroll();
        }
    }, [messages]);



    const fetchFirstQuestion = async () => {
        try {
            const response = await axios.post(config.API_BASE_URL + '/firstQuestion.php');

            const formattedQuestion = `
                <div style="display: flex; align-items: center; gap: 15px; margin-bottom:1em">
                    <img src="images/chat-icon-robot-30-30.png">
                    <strong style="margin: 0;">Tax.Network:</strong>
                </div>
                <div style="padding-left: 43px">
                    ${response.data.question}
                </div>
            `;


            /*setMessages(prevMessages => [
                ...prevMessages,
                { text: formattedQuestion, sender: 'bot', isHTML: true }
            ]);*/

            simulateTypingEffect(formattedQuestion, true);
            setFirstQuestionLoaded(true);
        } catch (error) {
            console.error('Error loading the initial question:', error);
        }
    };


    /*
        useEffect(() => {
            const handleScroll = () => {
                if (messagesContainerRef.current) {
                    const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
                    const percentageThreshold = 85; // 70% as an example
                    const heightThreshold = (scrollHeight - clientHeight) * (percentageThreshold / 100);
    
                    if (scrollTop >= heightThreshold && !firstChatLoad) {
                        console.log(`Scroll height exceeded ${percentageThreshold}% of the total height`);
                        setFirstChatLoad(true)
                        messagesContainerRef.current.removeEventListener('scroll', handleScroll);
                        
                        const lastMessage = getLastMessage()
    
                        setTimeout(() => {
                            simulateTypingEffect(lastMessage, true);
        
                            setTimeout(() => {
                                fetchFirstQuestion();
                            }, 6000);
                        }, 100);
                        
                    }
                }
            };
    
            if (messagesContainerRef.current) {
                messagesContainerRef.current.addEventListener('scroll', handleScroll);
            }
    
            return () => {
                if (messagesContainerRef.current) {
                    messagesContainerRef.current.removeEventListener('scroll', handleScroll);
                }
            };
        }, [messages]);
    
    */

    useEffect(() => {
        if (messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    }, [messages]);


    const callPhpService = async (history) => {
        let rankingContentDesktopLast = ''
        setStartRanking(true)
        setTimeout(() => {
            setShowLoadingAnimation(true);

        }, 9000);

        let urlRanking = ''

        if (!isMobile) {
            urlRanking = config.API_BASE_URL + '/ranking_desktop.php'
        }
        else {
            urlRanking = config.API_BASE_URL + '/ranking_mobile.php'
        }

        try {
            const response = await axios.post(urlRanking, {
                history: history
            });

            let rankingContent;

            if (!isMobile) {
                rankingContent = `
            <div style="padding-left: 43px">   
            <div style="margin-top: 15px">
            Your report is now complete and ready for review.
            </div>
            <div style="margin-top: 15px">
            We've identified potential savings of up to 45% in tax and operational expenses for you. Our assessment includes not just tax rates but also privacy regulations, estate planning convenience, political stability, and over 70 other factors from each jurisdiction. Based on this, we recommend the following ranking of locations:
            </div>
            <div style="display: flex; flex-direction: column; align-items: flex-start; margin-top: 20px; padding-left: 43px;">${response.data}</div>
            
            `;

                rankingContentDesktopLast = `
            <div style="padding-left: 43px">  
            <div style="margin-top: 15px">
            To learn more about your report and to talk to an expert, book your consultation here.
            </div>
            </div>
            <form style="display: flex; flex-direction: row; align-items: flex-start; margin-top: 20px; padding-left: 43px; gap: 5px;">
            <button style="background-color: #D6D6D6; color: #000; border: none; padding: 10px 20px; margin-right: 10px; border-radius: 39px; font-size: 0.9em; font-weight: 500;">Book a consultation</button>

            <button style="background-color: #D6D6D6; color: #000; border: none; padding: 10px 20px; border-radius: 39px; margin-right: 10px; font-size: 0.9em; font-weight: 500;">Send report</button>

            <button style="background-color: #D6D6D6; color: #000; border: none; margin-right: 10px; padding: 10px 20px; border-radius: 39px; font-size: 0.9em; font-weight: 500;">Save</button>

            <button style="background-color: #D6D6D6; color: #000; border: none; margin-right: 10px; padding: 10px 20px; border-radius: 39px; font-size: 0.9em; font-weight: 500;">Update</button>

            <button style="background-color: #D6D6D6; color: #000; border: none; margin-right: 10px; padding: 10px 20px; border-radius: 39px; font-size: 0.9em; font-weight: 500;">New</button>

            </form>
            </div>
            `;
            }
            else {
                rankingContent = `
            <div style="padding-left: 43px">   
            <div style="margin-top: 15px">
            Thank you. Your report is now complete and ready for review.
            </div>
            <div style="margin-top: 15px">
            We've identified potential savings of up to 45% in tax and operational expenses for you. Our assessment includes not just tax rates but also privacy regulations, estate planning convenience, political stability, and over 70 other factors from each jurisdiction. Based on this, we recommend the following ranking of locations:
            </div>
            <div style="display: flex; flex-direction: column; align-items: flex-start; margin-top: 20px; padding-left: 43px;">${response.data}</div>
            <div style="margin-top: 15px">
            To learn more about your report and to talk to an expert, book your consultation here.
            </div>
            </div>
            <form style="display: flex; flex-direction: column; align-items: flex-start; margin-top: 20px; padding-left: 43px; gap: 20px;">
            <button style="background-color: #D6D6D6; color: #000; border: none; padding: 10px 20px; margin-right: 10px; border-radius: 39px; font-size: 0.9em; font-weight: 500;">Book a consultation</button>

            <button style="background-color: #D6D6D6; color: #000; border: none; padding: 10px 20px; border-radius: 39px; margin-right: 10px; font-size: 0.9em; font-weight: 500;">Send report</button>

            <button style="background-color: #D6D6D6; color: #000; border: none; margin-right: 10px; padding: 10px 20px; border-radius: 39px; font-size: 0.9em; font-weight: 500;">Save</button>

            <button style="background-color: #D6D6D6; color: #000; border: none; margin-right: 10px; padding: 10px 20px; border-radius: 39px; font-size: 0.9em; font-weight: 500;">Update</button>

            <button style="background-color: #D6D6D6; color: #000; border: none; margin-right: 10px; padding: 10px 20px; border-radius: 39px; font-size: 0.9em; font-weight: 500;">New</button>

        </form>
            `;
            }



            setTimeout(() => {
                setShowLoadingAnimation(false);
                if (!isMobile) {
                    setMessages(prevMessages => [
                        ...prevMessages,
                        { text: rankingContent, sender: 'bot', isHTML: true }
                    ]);
                }
                else {
                    simulateTypingEffect(rankingContent, true);
                }

                if (!isMobile) {
                    simulateTypingEffect(rankingContentDesktopLast, true);
                }

            }, 12000);



        } catch (error) {
            console.error('Error calling the PHP service:', error);
        }
    };




    const sendMessage = async (e) => {

        e.preventDefault();
        if (!userInput.trim() || isTyping) return;

        /*
        old boot
        if (!loggedIn) {
            openLoginModal();
            return;
        }
         */
        if (!isFinished) {
            /*
            <div style="display: flex; align-items: center; gap: 15px; margin-bottom:20px; margin-top:40px; justify-content: flex-end;">
                            <img src="images/chat-icon-client-30-30.png">
                            <strong>You:</strong>
                             <div style="padding-left: 43px; width: 50%">
                        ${userInput}
                        </div>
                        </div>
                        */
            const formattedInput = `
        
                         <div style="display: flex; align-items: center; gap: 15px; margin-bottom:40px; margin-top:40px; justify-content: flex-end;">
                            
                             <div style="padding-left: 43px; border-radius: 25px;  background-color: #F2F2F2; padding: 40px; width: 75%">
                                ${userInput}
                            </div>
                        </div>
                       
                        `;

            setInteractionHistory(prevHistory => [
                ...prevHistory,
                {
                    questionId: currentQuestionId,
                    response: userInput
                }
            ]);



            setUserInput('');



            setTimeout(async () => {

                if (currentQuestionId != lastQuestionId) {

                    const dataInsertQuestion = {
                        id: currentDisplayQuestionId,
                        message: userInput,
                        ip: userIP,
                        uuid: userUUID,
                        client_id: userID
                    };

                    axios.post(config.API_BASE_URL + '/insertQuestion.php', dataInsertQuestion)
                        .then(response => {
                            console.log('Data submitted successfully:', response.data);
                        })
                        .catch(error => {
                            console.error('Error submitting data:', error);
                        });


                    try {
                        const response = await axios.post(config.API_BASE_URL + '/api.php', {
                            id: currentQuestionId + 1,
                            message: userInput,
                            ip: userIP
                        });

                        /*

                        old bot

                        const formattedResponse = `<div style="display: flex; align-items: center; gap: 15px;margin-bottom:20px; margin-top:40px;">
                        <img src="images/chat-icon-robot-30-30.png">
                        <strong style="margin: 0;">Tax.Network:</strong>
                        </div>
                        <div style="padding-left: 43px">            
                        ${response.data.question}
                        </div>
                        `;
                        */

                        const formattedResponse = `
                        <div>            
                        ${response.data.question}
                        </div>
                        `;

                        setMessages(prevMessages => [
                            ...prevMessages,
                            { text: formattedInput, sender: 'bot', isHTML: true }
                        ]);



                        simulateTypingEffect(formattedResponse, true);

                        setCurrentQuestionId(prevId => (prevId + 1) === 6 ? 47 : prevId + 1);

                        setDisplayCurrentQuestionId(currentDisplayQuestionId + 1)





                    } catch (error) {
                        console.error('Error sending message:', error);
                    }
                }
                else {

                    const dataInsertQuestion = {
                        id: currentDisplayQuestionId,
                        message: userInput,
                        ip: userIP,
                        uuid: userUUID,
                        client_id: userID
                    };

                    axios.post(config.API_BASE_URL + '/insertQuestion.php', dataInsertQuestion)
                        .then(response => {
                            console.log('Data submitted successfully:', response.data);
                        })
                        .catch(error => {
                            console.error('Error submitting data:', error);
                        });

                    /*
                    old bot

                    const formattedInput = `

                        <div style="display: flex; align-items: center; gap: 15px; margin-bottom:20px; margin-top:40px;">
                            <img src="images/chat-icon-client-30-30.png">
                            <strong>You:111</strong>
                        </div>
                        <div style="padding-left: 43px">
                        ${userInput}
                        </div>
                        `;


                    */

                    const formattedInput = `
                        
                        <div style="display: flex; align-items: center; gap: 15px; margin-bottom:40px; margin-top:40px; justify-content: flex-end;">
                            
                             <div style="padding-left: 43px; background-color: #F2F2F2; padding: 40px; width: 75%; border-radius: 25px;">
                                ${userInput}
                            </div>
                        </div>                 
                        `;

                    const newInteraction = {
                        questionId: currentQuestionId,
                        response: userInput
                    };

                    const updatedHistory = [...interactionHistory, newInteraction];

                    setInteractionHistory(updatedHistory);

                    setUserInput('');

                    setMessages(prevMessages => [
                        ...prevMessages,
                        { text: formattedInput, sender: 'bot', isHTML: true }
                    ]);

                    /*
                    old bot
                    const formattedLastMessage = `<div style="display: flex; align-items: center; gap: 15px;margin-bottom:20px; margin-top:40px;">
                            <img src="images/chat-icon-robot-30-30.png">
                            <strong style="margin: 0;">Tax.Network:</strong>
                            </div>
                            <div style="padding-left: 43px">     

                                <div>
                                Thank you.
                                </div>

                                <div style="margin-top: 15px">
                                We are analyzing your scenario with artificial intelligence and attributes from 150+ jurisdictions around the world and we will generate your report in seconds.
                                </div>

                                <div style="margin-top: 15px">
                                Please stay here with us.
                                </div>                   
                                                                     
                            </div>
                            `;
                */

                    const formattedLastMessage = `
          
                            <div>     
                                <strong>David:</strong> Thank you. Now, we are analyzing your scenario with artificial intelligence and attributes from jurisdictions around the world and we will generate your report in seconds. Please stay here with me.
                                <div>
                               
                            `;



                    const formattedResultMessage = `
                        <br/>
                        Your report is now complete and ready for review.
                        <br/><br/>
                    We've identified potential savings of up to <strong>45% in tax and operational expenses</strong>. Our assessment includes not just taxes but also privacy, legal framework, estate planning convenience, political stability, and over 70 other factors from each jurisdiction. Based on this, we recommend the following ranking of locations with local experts:
                 `;

                    const rankingContentDesktopLast = `
                            <div style="padding-left: 43px">  
                            <div style="margin-top: 15px">
                            To learn more about your report and to talk to an expert, book your consultation here.
                            </div>
                            </div>
                            <form style="display: flex; flex-direction: row; align-items: flex-start; margin-top: 20px; padding-left: 43px; gap: 5px;">
                            <button style="background-color: #D6D6D6; color: #000; border: none; padding: 10px 20px; margin-right: 10px; border-radius: 39px; font-size: 0.9em; font-weight: 500;">Book a consultation</button>
                
                            <button style="background-color: #D6D6D6; color: #000; border: none; padding: 10px 20px; border-radius: 39px; margin-right: 10px; font-size: 0.9em; font-weight: 500;">Send report</button>
                
                            <button style="background-color: #D6D6D6; color: #000; border: none; margin-right: 10px; padding: 10px 20px; border-radius: 39px; font-size: 0.9em; font-weight: 500;">Save</button>
                
                            <button style="background-color: #D6D6D6; color: #000; border: none; margin-right: 10px; padding: 10px 20px; border-radius: 39px; font-size: 0.9em; font-weight: 500;">Update</button>
                
                            <button style="background-color: #D6D6D6; color: #000; border: none; margin-right: 10px; padding: 10px 20px; border-radius: 39px; font-size: 0.9em; font-weight: 500;">New</button>
                
                            </form>
                            </div>
                            `;

                    simulateTypingEffect(formattedLastMessage, true, true, () => {

                        setShowLoadingAnimation(true);

                        setTimeout(() => {
                            setShowLoadingAnimation(false);

                            simulateTypingEffect(formattedResultMessage, true)

                            setTimeout(() => {

                                setMessages(prevMessages => [
                                    ...prevMessages,

                                    { component: <ExpandableTable />, sender: 'bot', isComponent: true },
                                    { text: rankingContentDesktopLast, sender: 'bot', isHTML: true }


                                ]);
                            }, 14000);
                        }, 9000);
                    });

                    
                    /*
                      --- default code - table gpt dynamic
                    
                    simulateTypingEffect(formattedLastMessage, true);

                    await callPhpService(updatedHistory);

                    
                    */
                   


                    setIsFinished(true)

                }
            }, 0);

        }
    };

    function checkLoginAndFocus() {

        inputRef.current.focus();
        /*

        old bot
        if (!loggedIn) {
            openLoginModal();
        } else if (inputRef.current) {
            inputRef.current.focus();
        }

         */
    }


    const loadDefaultResponse = async () => {

        /*

        old boot - login
        if (loggedIn && !startRanking && !isTyping && !responseLoaded.has(currentDisplayQuestionId)) {
        */

        if (!startRanking && !isTyping && !responseLoaded.has(currentDisplayQuestionId)) {
            setResponseLoaded(prev => new Set(prev).add(currentDisplayQuestionId));
            try {

                const response = await axios.post(`${config.API_BASE_URL}/getResponse.php`, {
                    id: currentDisplayQuestionId,
                    message: userInput,
                    ip: userIP
                });


                if (response.data) {
                    setUserInput(response.data.response_hypothetical);
                    inputRef.current.focus();

                } else {
                    console.log("No response found for the ID:", currentDisplayQuestionId);
                }
            } catch (error) {
                console.error('Failed to fetch response:', error);
                console.log("Responses not loaded or error occurred.");
            }
        }

    };

    useEffect(() => {
        if (showLoadingAnimation && messagesContainerRef.current) {
            messagesContainerRef.current.scrollTop = messagesContainerRef.current.scrollHeight;
        }
    }, [showLoadingAnimation]); 
    

    return (
        <>
           
            <div className={styles.container}>
                <div className={styles.spacemessagesContainerTop} />
                <div className={styles.messagesContainer} ref={messagesContainerRef}>
                    <div className={styles.messages}>
                        {messages.map((msg, index) => (
                            <div key={index} className={msg.sender === 'user' ? styles.userMessage : styles.botMessage}
                                style={msg.isBold ? { fontWeight: 'bold' } : null}>
                                {msg.isComponent ? (
                                    msg.component
                                ) : msg.isHTML ? (
                                    <div dangerouslySetInnerHTML={{ __html: msg.text }} />
                                ) : (
                                    msg.text
                                )}
                            </div>
                        ))}

                        
                        {showLoadingAnimation && (
                            <div style={{ marginTop: '5px', textAlign: 'left', paddingLeft: '0px' }}>
                                <img id="loading-animation" src="images/three-dots.gif" width="52" height="42" alt="Loading..." style={{ marginLeft: '6px' }} />
                                <div style={{ marginTop: '5px', fontSize: '90%', color: '#808080', fontWeight: 'bold' }}>Thinking</div>
                            </div>
                            
                        )}
                    </div>

                </div>

                <div className={styles.formContainer}>
                    <form onSubmit={sendMessage} className={styles.form}>

                        <div className={styles.formContainerInternal}>
                            <input
                                ref={inputRef}
                                className={styles.input}
                                value={userInput}
                                onChange={(e) => setUserInput(e.target.value)}
                                type="text"
                                placeholder="Type your response"
                                disabled={isTyping}
                                onClick={checkLoginAndFocus}
                            />
                            <div className={styles.buttonContainer}>
                                <button type="submit" className={styles.button} disabled={isTyping}>
                                    <i className="fas fa-arrow-up"></i>
                                </button>
                            </div>
                        </div>
                        <div className={styles.defaultResponseContainer}>
                            <a href="javascript:;" onClick={loadDefaultResponse} style={{ textDecoration: 'none', color: 'inherit' }} >
                                (load default response)
                            </a>
                        </div>
                    </form>
                </div>

                <div className={styles.spacemessagesContainerBottom} />
            </div>
        </>
    );
}

export default Chat;
